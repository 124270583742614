import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import LogoPositivo from '@monorepo-amais/commons/components/Icons/LabsPositivo'
import Convenios from '@monorepo-amais/commons/components/convenios/Convenios'
import imgixUrlOptimizerAuto from '@monorepo-amais/commons/utils/imgixUrlOptimizerAuto'

const ConveniosPage = ({ data }) => (
	<Layout>
		<SEO
			title={data.cosmicjsInformacoesEstaticas.metadata.seo.titulo}
			description={data.cosmicjsInformacoesEstaticas.metadata.seo.descricao}
			image={imgixUrlOptimizerAuto(data.cosmicjsInformacoesEstaticas.metadata.seo.imagem.imgix_url)}
		/>
		<Convenios data={data} logo={<LogoPositivo />} />
	</Layout>
)

export default ConveniosPage

export const query = graphql`
	query {
		cosmicjsInformacoesEstaticas(title: { eq: "Convenios" }) {
			metadata {
				titulo_da_pagina
				subtitulo
				informacoes
				titulo_telefone
				telefone
				informacoes_titulo
				informacoes_contato_novo
				subtexto
				contato
				seo {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
				telefones_contato {
					telefone
					titulo
				}
				titulo_contato
				convenio_geral
				modal_texto
				modal_botao_texto
			}
		}
		allConvenios {
			edges {
				node {
					descricao
					planos {
						descricao
						idLegado
					}
					detalhe {
						idConvenioLegado
						slug
						seo {
							titulo
						}
						informacoes
						telefones {
							descricao
							numero
						}
					}
					idLegado
				}
			}
		}
	}
`
